<template>
  <div class="grid survey-wrap">
    <div class="col-12">
      <div class="card">
        <Toast />

        <!-- Table Top Toolbar : New + Delete -->
        <Toolbar class="mb-4">
          <template #left>
            <div class="my-2">
              <Button label="New" icon="pi pi-plus" class="p-button-success mr-2" @click="openCreateSurveyForm" />
              <Button label="Delete" icon="pi pi-trash" class="p-button-danger" @click="(deletedSurvey = selectedSurvey), (display.deletedSurveyForm = true)" />
            </div>
          </template>
        </Toolbar>
        <!-- Table display all surveys -->
        <DataTable
          ref="dt"
          :value="surveyList"
          v-model:selection="selectedSurvey"
          data-key="id"
          :paginator="true"
          :rows="10"
          v-model:filters="filters"
          filter-display="menu"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[5, 10, 25]"
          current-page-report-template="Showing {first} to {last} of {totalRecords} surveys"
          responsive-layout="scroll"
          :loading="loading"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Manage Surveys</h5>
            </div>
          </template>
          <template #empty> No surveys found. </template>
          <template #loading> Loading surveys data. Please wait. </template>
          <Column selection-mode="multiple" header-style="width: 3rem" />
          <Column field="name" header="Name" :sortable="true">
            <template #body="{ data }">
              {{ data.name }}
            </template>
            <template #filter="{ filterModel }">
              <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name" />
            </template>
          </Column>
          <Column field="groups" header="Group">
            <template #body="slotProps">
              <div v-for="item in slotProps.data.groups" :key="item">
                {{ item.name }}
              </div>
            </template>
          </Column>
          <Column field="questions" header="Question">
            <template #body="slotProps">
              <div>
                <Tag> {{ slotProps.data.questions.length }} </Tag>
                <Button icon="pi pi-pencil" class="button-edit" v-tooltip.bottom="'Edit Questions'" @click="editQuestion(slotProps.data)" />
              </div>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="button-edit" v-tooltip.bottom="'Edit Survey'" @click="openEditSurveyForm(slotProps.data)" />
              <Button
                icon="pi pi-search"
                class="button-preview"
                :disabled="slotProps.data.questions.length == 0"
                v-tooltip.bottom="'Preview Survey'"
                @click="previewQuestion(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="button-delete"
                v-tooltip.bottom="'Delete Survey'"
                @click="(deletedSurvey = slotProps.data), (display.deletedSurveyForm = true)"
              />
            </template>
          </Column>
        </DataTable>

        <!-- Create Survey Form -->
        <Dialog v-model:visible="display.createSurveyForm" :style="{ width: '450px' }" header="New Survey" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Name</label>
            <InputText
              id="name"
              v-model.trim="newSurvey.name"
              required="true"
              autofocus
              :class="{
                'p-invalid': (newSurvey.name == null || newSurvey.name?.length == 0) && submit == true,
              }"
            />
            <small class="p-invalid-label" v-if="(newSurvey.name == null || newSurvey.name?.length == 0) && submit == true">Name is required.</small>
          </div>
          <div class="group-wrap">
            <div class="group-button-container">
              <Button icon="pi pi-minus" @click="minusGroup(this.newSurvey)" />
              <Button icon="pi pi-plus" @click="addGroup(this.newSurvey)" />
            </div>
            <div v-for="item of newSurvey.group" :key="item" class="field">
              <label for="name">Group</label>
              <InputText
                id="name"
                v-model.trim="item.name"
                required="true"
                :class="{
                  'p-invalid': (item.name == null || item.name?.length == 0) && submit == true,
                }"
                autofocus
              />
              <small class="p-invalid-label" v-if="(item.name == null || item.name?.length == 0) && submit == true">Group Name is required.</small>
            </div>
            <OrderList v-model="newSurvey.group" list-style="height:auto" data-key="sequence">
              <template #header> Please arrange the sequence of groups. </template>
              <template #item="slotProps">
                <div class="product-item">
                  <h6 class="p-mb-2">
                    {{ slotProps.item.name }}
                  </h6>
                </div>
              </template>
            </OrderList>
          </div>
          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="display.createSurveyForm = false" />
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="saveSurvey" />
          </template>
        </Dialog>

        <!-- Delete Survey Confirmation Form -->
        <Dialog v-model:visible="display.deletedSurveyForm" :style="{ width: '450px' }" header="Delete Survey" :modal="true" class="p-fluid">
          <div class="field">
            <label for="name">Are you Sure to delete this survey?</label>
          </div>
          <template #footer>
            <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSurvey" />
            <Button label="No" icon="pi pi-times" class="p-button-text" @click="(deletedSurvey = null), (display.deletedSurveyForm = false)" />
          </template>
        </Dialog>

        <!-- Update Survey Form -->
        <Dialog v-model:visible="display.editSurveyForm" :style="{ width: '450px' }" header="New Survey" :modal="true" class="p-fluid">
          {{ editSurvey }}
          <div class="field">
            <label for="name">Name</label>
            <InputText
              id="name"
              v-model.trim="editSurvey.name"
              required="true"
              autofocus
              :class="{
                'p-invalid': (editSurvey.name == null || editSurvey.name?.length == 0) && submit == true,
              }"
            />
            <small class="p-invalid-label" v-if="(editSurvey.name == null || editSurvey.name?.length == 0) && submit == true">Name is required.</small>
          </div>
          <div class="group-wrap">
            <div class="group-button-container">
              <Button icon="pi pi-minus" @click="minusGroup(this.editSurvey)" />
              <Button icon="pi pi-plus" @click="addGroup(this.editSurvey)" />
            </div>
            <div v-for="item of editSurvey.group" :key="item" class="field">
              <label for="name">Group</label>
              <InputText
                id="name"
                v-model.trim="item.name"
                required="true"
                :class="{
                  'p-invalid': (item.name == null || item.name?.length == 0) && submit == true,
                }"
                autofocus
              />
              <small class="p-invalid-label" v-if="(item.name == null || item.name?.length == 0) && submit == true">Group Name is required.</small>
            </div>
            <OrderList v-model="editSurvey.group" list-style="height:auto" data-key="sequence">
              <template #header> Please arrange the sequence of groups. </template>
              <template #item="slotProps">
                <div class="product-item">
                  <h6 class="p-mb-2">
                    {{ slotProps.item.name }}
                  </h6>
                </div>
              </template>
            </OrderList>
          </div>
          <template #footer>
            <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="display.editSurveyForm = false" />
            <Button label="Save" icon="pi pi-check" class="p-button-text" @click="updateSurvey" />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import surveyService from "../../service/survey";
import groupService from "../../service/group";
import { FilterMatchMode, FilterOperator } from "primevue/api";

export default {
  data() {
    return {
      filters: {
        name: {
          operator: FilterOperator.AND,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      newSurvey: {},
      editSurvey: {},
      display: {
        createSurveyForm: false,
        deletedSurveyForm: false,
        editSurveyForm: false,
      },
      submit: false,
      surveyList: null,
      selectedSurvey: null,
      deletedSurvey: null,
      loading: true,
    };
  },

  mounted() {
    this.updateTable();
  },
  methods: {
    updateTable() {
      this.loading = true;
      surveyService.getSurveyList().then((result) => {
        this.surveyList = {};
        this.surveyList = result;
        this.loading = false;
      });
    },
    openCreateSurveyForm() {
      this.submit = false;
      this.newSurvey = { name: null, group: [{ name: null, sequence: null }] };
      this.display.createSurveyForm = true;
    },
    openEditSurveyForm(data) {
      console.log(data);
      this.submit = false;
      this.editSurvey = { id: data.id, name: data.name, group: data.groups };
      this.display.editSurveyForm = true;
      console.log(this.editSurvey);
    },
    addGroup(survey) {
      survey.group = [
        ...survey.group,
        {
          name: null,
          sequence: null,
        },
      ];
    },
    minusGroup(survey) {
      if (survey.group.length > 1) {
        survey.group = survey.group.slice(0, -1);
      }
    },
    validateGroup(name) {
      let match = false;
      this.validate.group.forEach((group) => {
        if (group.name == name) {
          match = true;
        }
      });
      return match;
    },
    validation(survey) {
      let checkname = false;
      let checkgroup = true;
      if (survey.name != null) {
        if (survey.name.length != 0) {
          checkname = true;
        }
      }
      survey.group.forEach((group) => {
        if (group.name == null) {
          checkgroup = false;
        } else if (group.name.length == 0) {
          checkgroup = false;
        }
      });
      return checkgroup && checkname;
    },
    saveSurvey() {
      this.submit = true;
      if (this.validation(this.newSurvey)) {
        this.display.createSurveyForm = false;
        this.loading = true;
        let promises = [];
        let groupid = [];
        this.newSurvey.group.forEach((group, index) => {
          group.sequence = index + 1;
          let obj = {};
          obj.name = group.name;
          obj.sequence = group.sequence;
          promises.push(
            groupService.postGroup(obj).then((result) => {
              console.log(result);
              groupid.push(result.id);
            })
          );
        });
        console.log(groupid);

        Promise.all(promises).then(() => {
          let obj = {};
          obj.name = this.newSurvey.name;
          obj.groups = groupid;
          surveyService.postSurvey(obj).then(() => {
            this.updateTable();
          });
        });
      }
    },
    updateSurvey() {
      this.submit = true;
      if (this.validation(this.editSurvey)) {
        this.display.editSurveyForm = false;
        this.loading = true;
        let promises = [];
        let groupid = [];
        this.editSurvey.group.forEach((group, index) => {
          group.sequence = index;
          let obj = {};
          obj.name = group.name;
          obj.sequence = group.sequence;
          if (!group.id) {
            promises.push(
              groupService.postGroup(obj).then((result) => {
                console.log(result);
                groupid.push(result.id);
              })
            );
          } else {
            obj.id = group.id;
            promises.push(
              groupService.updateGroup(obj).then((result) => {
                console.log(result);
                groupid.push(result.id);
              })
            );
          }
        });

        Promise.all(promises).then(() => {
          let obj = {};
          obj.name = this.editSurvey.name;
          obj.groups = groupid;
          obj.id = this.editSurvey.id;

          surveyService.updateSurvey(obj).then(() => {
            this.updateTable();
          });
        });
      }
    },

    //wonder if need to delete question as well

    deleteSurvey() {
      this.loading = true;
      this.display.deletedSurveyForm = false;
      this.deletedSurvey = Array.isArray(this.deletedSurvey) ? this.deletedSurvey : Array(this.deletedSurvey);
      let promises = [];
      this.deletedSurvey.forEach((survey) => {
        let obj = { id: survey.id };
        promises.push(surveyService.deleteSurvey(obj));
        survey.groups.forEach((group) => {
          let grpobj = { id: group.id };
          promises.push(groupService.deleteGroup(grpobj));
        });
      });

      Promise.all(promises).then(() => {
        this.updateTable();
        this.deletedSurvey = null;
      });
    },
    editQuestion(survey) {
      this.$router.push({ path: "/survey/" + survey.id });
    },
    previewQuestion(survey) {
      this.$router.push({ path: "/surveyform/" + survey.id });
    },
  },
};
</script>

<style scoped lang="scss">
.group-button-container {
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 0.5rem;
  }
}
.survey-wrap {
  ::v-deep(.p-datatable-table) {
    .p-datatable-tbody {
      tr {
        td:nth-child(4) {
          div {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .p-tag {
              height: 1.5rem;
              margin-right: 1rem;
            }
          }
        }
        td {
          .button-edit {
            border-radius: 30%;
            background: #a9a9a9;
            border-color: #a9a9a9;
            margin-right: 1rem;
          }
          .button-preview {
            border-radius: 30%;
            background: #878484;
            border-color: #878484;
            margin-right: 1rem;
          }

          .button-delete {
            border-radius: 30%;
            background: #4c4c4c;
            border-color: #4c4c4c;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
.p-invalid-label {
  color: red;
}
</style>
